html,
body {
  padding: 0;
  margin: 0;
  font-family: "Moderat", "Helvetica", "sans-serif";
  overflow-x: hidden;
  width: 100%;
  height: auto;
  background: none;
  background: #F9F7F3;
  font-weight: 400;
}

h1, h2, h3 {
  font-weight: 300;
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 13px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

*,
.box-content {
  box-sizing: border-box !important;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5 !important;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  width: 5px !important;
  background-color: #f5f5f5 !important;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: #222 !important;
  box-shadow: inset 3px 0 15px rgba(0, 0, 0, 0.3);
}

.main-container {
  position: relative;
  transition: 1s ease all;
  opacity: 1;
  margin-top: 0;
}

.layout-container > div{
  position: relative;
}

.first-load > div {
  left: 0 !important;
}

/*
.page-transition-exit .scrollmagic-pin-spacer > div {
  transition: 1s ease all;
}

.layout-container .page-transition-exit-active div#header-darkbg {
  display: none;
}

.layout-container:not(.article) .page-transition-exit-active .scrollmagic-pin-spacer > div {
  transform: translateX(100vw);
  transition: 1s ease all;
  opacity: 0;
}

.layout-container:not(.article) .page-transition-exit-active  #header {
  transform: translateX(100vw);
  transition: 1s ease all;
  opacity: 0;
}

.layout-container:not(.article) .page-transition-exit-active .main-container {
  transform: translateX(100vw);
  opacity: 0;
}

.layout-container.article .page-transition-exit-active .main-container {
  top: -200vw;
  opacity: 0;
}
*/
