@font-face {
    font-family: 'Moderat';
    src: url('Moderat-Light.woff2') format('woff2'),
        url('Moderat-Light.woff') format('woff'),
        url('Moderat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('Moderat-Light_1.woff2') format('woff2'),
        url('Moderat-Light_1.woff') format('woff'),
        url('Moderat-Light_1.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('Moderat-Medium.woff2') format('woff2'),
        url('Moderat-Medium.woff') format('woff'),
        url('Moderat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('Moderat-Regular.woff2') format('woff2'),
        url('Moderat-Regular.woff') format('woff'),
        url('Moderat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tiempo';
    src: url('TiemposText-Regular.woff2') format('woff2'),
        url('TiemposText-Regular.woff') format('woff'),
        url('TiemposText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tiempo';
    src: url('TiemposText-RegularItalic.woff2') format('woff2'),
        url('TiemposText-RegularItalic.woff') format('woff'),
        url('TiemposText-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('Moderat-Medium_1.woff2') format('woff2'),
        url('Moderat-Medium_1.woff') format('woff'),
        url('Moderat-Medium_1.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderat';
    src: url('Moderat-Regular_1.woff2') format('woff2'),
        url('Moderat-Regular_1.woff') format('woff'),
        url('Moderat-Regular_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

